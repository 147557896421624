import api from '@admin/api/service';

export default {
  getCheckInvoice(approvalId, params) {
    return api.get(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/create`,
      { params }
    );
  },

  postDisbursementInvoice(approvalId, params) {
    return api.post(
      `singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice`,
      params
    );
  },

  getExistingInvoice(approvalId, disbursementInvoiceId) {
    return api.get(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/${disbursementInvoiceId}`
    );
  },

  putExistingInvoice(approvalId, disbursementInvoiceId, params) {
    return api.put(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice/${disbursementInvoiceId}`,
      params
    );
  },

  getManualListing(approvalId) {
    return api.get(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice-manual/create`
    );
  },

  postDisbursementInvoiceManual(approvalId, params) {
    return api.post(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice-manual`,
      params
    );
  },

  getExistingInvoiceManual(approvalId, invoiceDisbursementId) {
    return api.get(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice-manual/${invoiceDisbursementId}`
    );
  },

  putExistingInvoiceManual(approvalId, invoiceDisbursementId, params) {
    return api.put(
      `/singgahsini/api/income/approval-property-disbursement/${approvalId}/invoice-manual/${invoiceDisbursementId}`,
      params
    );
  },
};
