import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default {
  methods: {
    async handleCheckInvoice() {
      this.handleResetValue();

      this.loading.invoiceCheck = true;
      this.error.searchInvoice.bool = false;
      this.error.searchInvoice.reason = '';
      this.show.changeTotalPrice = false;
      this.disabled.save = true;

      let response = null;

      if (this.isEditTransaction) {
        response = await this.fetchGetExistingInvoice();
      } else {
        response = await this.fetchGetCheckInvoice({
          invoice_number: `${this.value.invoice_number}`,
        });
      }

      if (response.hasError) {
        this.error.searchInvoice.bool = true;
        this.error.searchInvoice.reason = 'not-found';
        if (response.data.issue.code !== 990404) {
          console.error(response.data.issue.message);
        }
      } else {
        response.data.data.period_start = dayjs(
          response.data.data.period_start,
          'YYYY-MM-DD'
        ).$d;
        response.data.data.period_end = dayjs(
          response.data.data.period_end,
          'YYYY-MM-DD'
        ).$d;

        Object.assign(this.value, response.data.data);

        if (this.manualListing.revenue_model === 'static') {
          this.value.previous_disbursed_amount = this.value.static_price;
        } else if (this.manualListing.revenue_model === 'commission') {
          this.value.previous_disbursed_amount = this.value.room_price;
        }

        if (this.isEditTransaction) {
          this.value.previous_reason_amount_changed =
            response.data.data.reason_amount_changed || '';
          if (response.data.data.reason_amount_changed) {
            this.hasDisbursementAmountUpdated = true;
          }
        }

        this.$nextTick(() => {
          this.$refs.invoice_amount.manualTrigger();
          this.$refs.static_price.manualTrigger();
          this.$refs.room_price.manualTrigger();
        });

        this.show.changeTotalPrice = true;
        this.disabled.save = false;
      }

      this.loading.invoiceCheck = false;
      this.loading.existingInvoice = false;
    },

    async handleSave() {
      this.loading.save = true;

      const response = await this.fetchPostDisbursementInvoice({
        invoice_id: this.value.invoice_id,
        amount: this.value.disbursed_amount,
        amount_changed_reason: this.value.reason_amount_changed || null,
      });

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.$toast.show('Berhasil menambahkan transaksi');
        this.$router.push(`/disbursement/detail-transfer/${this.approvalId}`);
      }

      this.loading.save = false;
    },

    async handleUpdate() {
      this.loading.save = true;

      const response = await this.fetchPutExistingInvoice({
        amount: this.value.disbursed_amount,
        amount_changed_reason: this.value.reason_amount_changed || null,
      });

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details);
      } else {
        this.$toast.show('Jumlah Ditransfer berhasil diubah');
        this.$router.push(`/disbursement/detail-transfer/${this.approvalId}`);
      }

      this.loading.save = false;
    },

    async handleGetManualListing() {
      this.loading.existingInvoice = true;

      const response = await this.fetchGetManualListing();

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(error);
      } else {
        this.manualListing = response.data.data;
        this.roomTypeOptions = this.manualListing.listing_detail?.map(item => ({
          val: item.room_id,
          label: item.room_type,
        }));
      }
    },

    async handlePostDisbursementInvoiceManual() {
      this.loading.save = true;

      const response = await this.fetchPostDisbursementInvoiceManual({
        invoice_number: this.value.invoice_number,
        tenant_type: this.value.from_booking ? 'booking' : 'dbet',
        tenant_name: this.value.tenant_name,
        designer_id: this.value.unit_type,
        designer_room_id: this.value.room_number,
        duration_unit: this.value.duration_unit,
        start_date: dayjs(this.value.period_start).format('YYYY-MM-DD'),
        end_date: dayjs(this.value.period_end).format('YYYY-MM-DD'),
        amount: this.convertToNumber(this.value.invoice_amount),
        disburse_amount: Number(this.value.disbursed_amount),
        disburse_amount_changed_reason:
          this.value.reason_amount_changed || null,
      });

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details || error);

        this.loading.save = false;
      } else {
        this.$toast.show('Berhasil menambahkan transaksi');
        this.$router.push(`/disbursement/detail-transfer/${this.approvalId}`);
      }
    },

    async handleGetExistingInvoiceManual() {
      const response = await this.fetchGetExistingInvoiceManual();

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details || error);
      } else {
        const values = response.data.data;

        this.value.invoice_number = values.invoice_number;
        this.value.from_booking = values.tenant_type === 'booking';
        this.value.tenant_name = values.tenant_name;
        this.value.unit_type = values.designer_id;
        this.value.duration_unit = values.duration_unit;
        this.value.period_start = dayjs(values.start_date).$d;
        this.value.period_end = dayjs(values.end_date).$d;
        this.value.static_price = values.static_price;
        this.value.room_price = values.selling_price;
        this.value.invoice_amount = values.amount;
        this.value.disbursed_amount = values.disburse_amount;
        this.value.reason_amount_changed =
          values.disburse_amount_changed_reason;

        this.value.previous_reason_amount_changed =
          values.disburse_amount_changed_reason || '';

        if (this.manualListing.revenue_model === 'static') {
          this.value.previous_disbursed_amount = this.value.static_price;
        } else if (this.manualListing.revenue_model === 'commission') {
          this.value.previous_disbursed_amount = this.value.room_price;
        }

        if (values.disburse_amount_changed_reason) {
          this.hasDisbursementAmountUpdated = true;
        }

        this.show.changeTotalPrice = true;

        const selectedRoomType = this.manualListing.listing_detail.find(
          item => item.room_id === this.value.unit_type
        );

        this.roomNumberOptions = selectedRoomType.room_number.map(item => ({
          val: item.id,
          label: item.name,
        }));

        this.value.room_number = values.designer_room_id;

        this.$nextTick(() => {
          this.$refs.invoice_amount.manualTrigger();
          this.$refs.static_price.manualTrigger();
          this.$refs.room_price.manualTrigger();
        });
      }

      this.loading.existingInvoice = false;
    },

    async handlePutExistingInvoiceManual() {
      this.loading.save = true;

      const response = await this.fetchPutExistingInvoiceManual({
        invoice_number: this.value.invoice_number,
        tenant_type: this.value.from_booking ? 'booking' : 'dbet',
        tenant_name: this.value.tenant_name,
        designer_id: this.value.unit_type,
        designer_room_id: this.value.room_number,
        duration_unit: this.value.duration_unit,
        start_date: dayjs(this.value.period_start).format('YYYY-MM-DD'),
        end_date: dayjs(this.value.period_end).format('YYYY-MM-DD'),
        amount: this.convertToNumber(this.value.invoice_amount),
        disburse_amount: Number(this.value.disbursed_amount),
        disburse_amount_changed_reason:
          this.value.reason_amount_changed || null,
      });

      if (response.hasError) {
        const error = response.data.issue?.message || response.data.message;
        this.$toast.show(error);
        console.error(response.data.issue?.details || error);

        this.loading.save = false;
      } else {
        this.$toast.show('Berhasil edit transaksi');
        this.$router.push(`/disbursement/detail-transfer/${this.approvalId}`);
      }
    },
  },
};
