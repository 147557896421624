<template>
  <div class="form-manual-transaction">
    <page-header
      :back-to="`/disbursement/detail-transfer/${approvalId}`"
      :title="`${
        isEditTransaction || isEditManual ? 'Ubah' : 'Tambah'
      } Transaksi`"
    />

    <section v-show="loading.existingInvoice">
      <bg-skeleton width="100%" height="32px" />
    </section>

    <section v-show="!loading.existingInvoice">
      <div class="mb-32">
        <bg-text size="heading-3">{{ kosName }}</bg-text>
      </div>

      <div class="mb-44">
        <bg-field label="Jenis Invoice" data-testid="invoice-type">
          <template v-if="isEditTransaction || isEditManual">
            <div v-show="isEditTransaction">Invoice Mamikos</div>
            <div v-show="isEditManual">Invoice Manual</div>
          </template>
          <template v-else>
            <bg-radio
              name="invoice"
              id="invoice-mamikos"
              class="radio-item"
              label="Invoice Mamikos"
              custom-value="mamikos"
              v-model="value.invoice_type"
            />
            <bg-radio
              name="invoice"
              id="invoice-manual"
              class="radio-item"
              label="Invoice Manual"
              custom-value="manual"
              v-model="value.invoice_type"
            />
          </template>
        </bg-field>

        <bg-field
          label="No Invoice"
          class="search-invoice clearfix"
          :error="error.searchInvoice.bool"
          :message="invoiceErrorMessage"
        >
          <bg-input
            v-model="value.invoice_number"
            data-testid="invoice-number"
            :icon-right="error.searchInvoice.bool ? 'information-round' : ''"
            :disabled="disableInvoiceInput || isEditTransaction"
          />
          <bg-button
            v-if="!(isEditTransaction || isInvoiceManual)"
            variant="secondary"
            class="btn-input ml-16"
            data-testid="invoice-number-search"
            size="lg"
            :disabled="disableInvoiceInput || !value.invoice_number"
            :loading="loading.invoiceCheck"
            @click="handleCheckInvoice"
            >Cari</bg-button
          >
        </bg-field>

        <bg-field label="Tipe Penyewa" data-testid="tipe-penyewa">
          <template v-if="isEditTransaction || isEditManual">
            {{ value.from_booking ? 'Booking' : 'DBET' }}
          </template>
          <template v-else>
            <bg-radio
              name="tenant-type"
              id="tenant-booking"
              class="radio-item"
              label="Booking"
              :custom-value="true"
              v-model="value.from_booking"
              :disabled="isDisabled"
            />
            <bg-radio
              name="tenant-type"
              id="tenant-dbet"
              class="radio-item"
              label="DBET"
              :custom-value="false"
              v-model="value.from_booking"
              :disabled="isDisabled"
            />
          </template>
        </bg-field>
      </div>

      <bg-divider class="mb-32" />

      <bg-grid>
        <bg-grid-item :col="6">
          <bg-field
            label="Nama Penyewa"
            :error="isNameTooLong"
            :message="
              isNameTooLong
                ? 'Nama tenant tidak boleh melebihi 100 karakter'
                : ''
            "
          >
            <bg-input
              v-model="value.tenant_name"
              placeholder="Masukkan nama penyewa"
              data-testid="nama-penyewa"
              :disabled="isDisabled"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6">
          <bg-field label="Tipe Kamar ">
            <bg-input
              v-if="!isInvoiceManual"
              class="fake-dropdown"
              v-model="value.unit_type"
              placeholder="Masukkan tipe kamar"
              icon-right="dropdown-down"
              data-testid="tipe-kamar"
              disabled
            />
            <bg-select
              v-else
              v-model="value.unit_type"
              :options="roomTypeOptions"
              data-testid="tipe-kamar"
              @change="handleChangeRoomType"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6">
          <bg-field label="Nomor Kamar">
            <bg-input
              class="fake-dropdown"
              v-if="!isInvoiceManual"
              v-model="value.room_number"
              placeholder="Masukkan nomor kamar"
              icon-right="dropdown-down"
              data-testid="nomor-kamar"
              disabled
            />
            <bg-select
              v-else
              v-model="value.room_number"
              :options="roomNumberOptions"
              :disabled="!value.unit_type"
              data-testid="nomor-kamar"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6">
          <bg-field label="Hitungan Sewa">
            <bg-select
              v-model="value.duration_unit"
              :options="rentPeriodOptions"
              :disabled="isDisabled"
              placeholder="Pilih hitungan sewa"
              data-testid="hitungan-sewa"
              @input="handleInputDuration"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6">
          <bg-field label="Periode Invoice">
            <bg-grid>
              <bg-grid-item :col="6" class="mb-0">
                <bg-datepicker
                  v-model="value.period_start"
                  placeholder="Awal Tanggal Periode"
                  format="dd/MM/yyyy"
                  data-testid="periode-awal"
                  :disabled="isDisabled"
                  @input="handleInputPeriodStart"
                />
              </bg-grid-item>
              <bg-grid-item :col="6" class="mb-0">
                <bg-datepicker
                  v-model="value.period_end"
                  placeholder="Akhir Tanggal Periode"
                  format="dd/MM/yyyy"
                  data-testid="periode-akhir"
                  disabled
                />
              </bg-grid-item>
            </bg-grid>
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="6">
          <input-currency-masking
            field-label="Pembayaran di Invoice"
            prefix="Rp"
            :disabled="isDisabled"
            v-model="value.invoice_amount"
            ref="invoice_amount"
            :field-error="isInvoiceAmountOverLimit"
            :field-message="
              isInvoiceAmountOverLimit
                ? 'Tidak boleh melebihi Rp 50.000.000'
                : ''
            "
            max-char="10"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <input-currency-masking
            field-label="Harga Statis"
            prefix="Rp"
            disabled
            v-model="value.static_price"
            ref="static_price"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <input-currency-masking
            field-label="Harga Sewa Kamar"
            prefix="Rp"
            disabled
            v-model="roomPrice"
            ref="room_price"
          />
        </bg-grid-item>

        <bg-grid-item :col="6">
          <bg-field
            label="Jumlah Ditransfer"
            class="total-transfer"
            data-testid="total-transfer"
          >
            <p>{{ totalAmount }}</p>
            <bg-label-rainbow v-if="hasDisbursementAmountUpdated"
              >Nominal telah diubah</bg-label-rainbow
            >
            <bg-link
              v-if="show.changeTotalPrice"
              variant="medium"
              data-testid="ubah-nominal"
              @click="show.modal = true"
              >Ubah</bg-link
            >
          </bg-field>
        </bg-grid-item>

        <bg-grid-item v-if="hasDisbursementAmountUpdated" :col="6">
          <bg-field label="Alasan Ubah Jumlah Transfer">
            <p>{{ value.reason_amount_changed }}</p>
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <bg-grid>
        <bg-grid-item v-if="isInvoiceManual">
          <bg-button
            v-if="isEditManual"
            variant="primary"
            :disabled="disableManualSave"
            :loading="loading.save"
            data-testid="simpan-transaksi"
            @click="handlePutExistingInvoiceManual"
            >Simpan</bg-button
          >
          <bg-button
            v-else
            variant="primary"
            :disabled="disableManualSave"
            :loading="loading.save"
            data-testid="simpan-transaksi"
            @click="handlePostDisbursementInvoiceManual"
            >Simpan</bg-button
          >
        </bg-grid-item>
        <bg-grid-item v-else>
          <bg-button
            v-if="isEditTransaction"
            variant="primary"
            :disabled="disabled.save"
            :loading="loading.save"
            data-testid="simpan-transaksi"
            @click="handleUpdate"
            >Simpan</bg-button
          >
          <bg-button
            v-else
            variant="primary"
            :disabled="disabled.save"
            :loading="loading.save"
            data-testid="simpan-transaksi"
            @click="handleSave"
            >Simpan</bg-button
          >
        </bg-grid-item>
      </bg-grid>
    </section>

    <edit-total-transfer-modal
      :is-edit="isEditTransaction || isEditManual"
      :amount-reason.sync="value"
      :is-open.sync="show.modal"
      :original-amount="value.previous_disbursed_amount"
      :original-reason="value.previous_reason_amount_changed"
      :updated.sync="hasDisbursementAmountUpdated"
    />
  </div>
</template>

<script>
import {
  BgText,
  BgRadio,
  BgInput,
  BgSkeleton,
  BgDatepicker,
  BgField,
  BgGrid,
  BgGridItem,
  BgButton,
  BgDivider,
  BgSelect,
  BgLink,
  BgLabelRainbow,
} from 'bangul-vue';
import PageHeader from '@admin_molecules/PageHeader';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import InputCurrencyMasking from '@admin_molecules/InputCurrencyMasking';
import { currencyFormatter } from 'Utils/formatter';

import fetchApi from './mixins/fetchApi.js';
import handleApi from './mixins/handleApi.js';

dayjs.extend(customParseFormat);

export default {
  name: 'FormManualTransaction',

  components: {
    InputCurrencyMasking,
    BgText,
    BgRadio,
    BgInput,
    BgSkeleton,
    BgDatepicker,
    BgField,
    BgGrid,
    BgGridItem,
    BgButton,
    BgDivider,
    BgSelect,
    BgLink,
    BgLabelRainbow,
    PageHeader,
    EditTotalTransferModal: () => import('./partials/EditTotalTransferModal'),
  },

  mixins: [fetchApi, handleApi],

  data() {
    return {
      value: {
        previous_reason_amount_changed: '',
        reason_amount_changed: '',

        invoice_number: null,
        invoice_type: null,

        from_booking: null,
        tenant_name: null,
        room_number: null,
        unit_type: null,
        duration_unit: null,
        period_start: null,
        period_end: null,
        invoice_amount: 0,
        static_price: 0,
        room_price: 0,
        disbursed_amount: 0,
        previous_disbursed_amount: 0,
      },

      rentPeriodOptions: [
        {
          val: 'day',
          label: 'Per hari',
        },
        {
          val: 'week',
          label: 'Per minggu',
        },
        {
          val: 'month',
          label: 'Per bulan',
        },
        {
          val: '3_month',
          label: 'Per 3 bulan',
        },
        {
          val: '6_month',
          label: 'Per 6 bulan',
        },
        {
          val: 'year',
          label: 'Per tahun',
        },
      ],

      manualListing: {},

      roomTypeOptions: [],
      roomNumberOptions: [],

      disabled: {
        save: true,
      },

      loading: {
        invoiceCheck: false,
        existingInvoice: false,
        save: false,
      },

      error: {
        searchInvoice: {
          bool: false,
          reason: '',
        },
      },

      errorMessage: {
        invoiceNotFound:
          'Invoice tidak ditemukan, pastikan nomer invoice dengan benar',
        invoiceTooLong: 'Nomor invoice melebihi dari 100 karakter',
      },

      show: {
        modal: false,
        changeTotalPrice: false,
        priceChanged: false,
      },

      hasDisbursementAmountUpdated: false,
    };
  },

  computed: {
    kosName() {
      return this.$store.state.detailTransfer.data.data.property_name;
    },

    isEditTransaction() {
      return this.$route.name === 'disbursement.edit-mamikos-transaction';
    },

    isEditManual() {
      return this.$route.name === 'disbursement.edit-manual-transaction';
    },

    isInvoiceManual() {
      return this.value.invoice_type === 'manual';
    },

    invoiceIdToBeEdited() {
      const disbursementInvoiceId = '';
      if (this.isEditTransaction || this.isEditManual) {
        return this.$route.params.disbursement_invoice_id;
      }
      return disbursementInvoiceId;
    },

    approvalId() {
      return this.$route.params.approval_id;
    },

    isDisabled() {
      return !this.isInvoiceManual;
    },

    totalAmount() {
      const amount =
        this.value.disbursed_amount || this.value.previous_disbursed_amount;
      return amount === 0 ? '-' : `Rp ${this.inputMoneyAmount(amount)}`;
    },

    disableInvoiceInput() {
      return !this.value.invoice_type;
    },

    isInvoiceAmountOverLimit() {
      return this.convertToNumber(this.value.invoice_amount) > 50000000;
    },

    isNameTooLong() {
      return this.value.tenant_name?.length > 100;
    },

    invoiceErrorMessage() {
      let message = '';

      if (this.error.searchInvoice.reason === 'not-found') {
        message = this.errorMessage.invoiceNotFound;
      } else if (this.error.searchInvoice.reason === 'too-long') {
        message = this.errorMessage.invoiceTooLong;
      }

      return message;
    },

    disableManualSave() {
      let disable = true;

      if (this.isInvoiceManual) {
        disable = !(
          this.value.invoice_number &&
          this.value.invoice_type &&
          typeof this.value.from_booking === 'boolean' &&
          this.value.tenant_name &&
          this.value.room_number &&
          this.value.unit_type &&
          this.value.duration_unit &&
          this.value.period_start &&
          this.value.period_end &&
          this.value.invoice_amount &&
          this.value.disbursed_amount &&
          !this.error.searchInvoice.bool &&
          !this.isInvoiceAmountOverLimit &&
          !this.isNameTooLong
        );
      }

      return disable;
    },

    invoiceTypeOnChange() {
      return this.value.invoice_type;
    },

    roomPrice() {
      return this.value.room_price || 0;
    },
  },

  watch: {
    invoiceTypeOnChange() {
      this.handleResetValue();
      this.value.invoice_number = '';
      this.value.disbursed_amount = 0;
      this.value.previous_disbursed_amount = 0;
      this.show.changeTotalPrice = false;
    },
    // eslint-disable-next-line func-names
    'value.invoice_number': function (val) {
      if (val.length > 100) {
        this.error.searchInvoice.bool = true;
        this.error.searchInvoice.reason = 'too-long';
      } else {
        this.error.searchInvoice.bool = false;
        this.error.searchInvoice.reason = '';
      }
    },
  },

  async beforeMount() {
    this.loading.existingInvoice = true;

    if (
      !(this.isEditTransaction || this.isInvoiceManual) || this.isInvoiceManual
    ) {
      await this.handleGetManualListing();
    }

    if (this.isEditTransaction || this.isEditManual) {
      this.manualListing.revenue_model = this.$store.state.detailTransfer.data.data.original_revenue_model_name;
    }

    if (this.isEditTransaction) {
      this.value.invoice_type = 'mamikos';
      await this.handleCheckInvoice();
    } else if (this.isEditManual) {
      this.value.invoice_type = 'manual';
      await this.handleGetExistingInvoiceManual();
    }

    this.loading.existingInvoice = false;
  },

  methods: {
    async handleResetValue() {
      const reset = {
        from_booking: null,
        tenant_name: null,
        room_number: null,
        unit_type: null,
        duration_unit: null,
        period_start: null,
        period_end: null,
        invoice_amount: 0,
        static_price: 0,
        room_price: 0,
        disbursed_amount: 0,
      };

      await Object.assign(this.value, reset);

      this.$refs.invoice_amount.manualTrigger();
      this.$refs.static_price.manualTrigger();
      this.$refs.room_price.manualTrigger();
    },

    inputMoneyAmount(value) {
      return currencyFormatter(value);
    },

    handleInputPeriodStart(value) {
      const startDate = value;
      let endDate = null;

      switch (this.value.duration_unit) {
        case 'day':
          endDate = dayjs(startDate).add(1, 'day').$d;
          break;
        case 'week':
          endDate = dayjs(startDate).add(1, 'week').$d;
          break;
        case 'month':
          endDate = dayjs(startDate).add(1, 'month').$d;
          break;
        case '3_month':
          endDate = dayjs(startDate).add(3, 'month').$d;
          break;
        case '6_month':
          endDate = dayjs(startDate).add(6, 'month').$d;
          break;
        case 'year':
          endDate = dayjs(startDate).add(1, 'year').$d;
          break;
        default:
          break;
      }

      this.value.period_end = endDate;
    },

    handleInputDuration() {
      if (this.value.period_start) {
        this.handleInputPeriodStart(this.value.period_start);
      }
    },

    handleChangeRoomType(payload) {
      const selectedRoomType = this.manualListing.listing_detail.find(
        item => item.room_id === payload
      );

      this.value.static_price = selectedRoomType.static_price;
      this.value.room_price = selectedRoomType.selling_price;

      if (this.manualListing.revenue_model === 'static') {
        this.value.disbursed_amount = this.value.static_price;
        this.value.previous_disbursed_amount = this.value.static_price;
      } else if (this.manualListing.revenue_model === 'commission') {
        this.value.disbursed_amount = this.value.room_price;
        this.value.previous_disbursed_amount = this.value.room_price;
      }

      this.$nextTick(() => {
        this.$refs.static_price.manualTrigger();
        this.$refs.room_price.manualTrigger();
      });

      this.show.changeTotalPrice = true;

      this.roomNumberOptions = selectedRoomType.room_number.map(item => ({
        val: item.id,
        label: item.name,
      }));
    },

    convertToNumber(value) {
      return Number(value.toString().replace(/\D/g, ''));
    },
  },
};
</script>

<style src="./FormManualTransaction.scss" lang="scss" scoped />
