import disbursementApi from '@admin_endpoints/disbursement-manual-transaction';

export default {
  methods: {
    async fetchGetCheckInvoice(params) {
      let response = {};

      try {
        response = await disbursementApi.getCheckInvoice(
          this.approvalId,
          params
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchPostDisbursementInvoice(params) {
      let response = {};

      try {
        response = await disbursementApi.postDisbursementInvoice(
          this.approvalId,
          params
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchGetExistingInvoice() {
      let response = {};

      try {
        response = await disbursementApi.getExistingInvoice(
          this.approvalId,
          this.invoiceIdToBeEdited
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchPutExistingInvoice(params) {
      let response = {};

      try {
        response = await disbursementApi.putExistingInvoice(
          this.approvalId,
          this.invoiceIdToBeEdited,
          params
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchGetManualListing() {
      let response = {};

      try {
        response = await disbursementApi.getManualListing(this.approvalId);
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchPostDisbursementInvoiceManual(params) {
      let response = {};

      try {
        response = await disbursementApi.postDisbursementInvoiceManual(
          this.approvalId,
          params
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchGetExistingInvoiceManual() {
      let response = {};

      try {
        response = await disbursementApi.getExistingInvoiceManual(
          this.approvalId,
          this.invoiceIdToBeEdited
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },

    async fetchPutExistingInvoiceManual(params) {
      let response = {};

      try {
        response = await disbursementApi.putExistingInvoiceManual(
          this.approvalId,
          this.invoiceIdToBeEdited,
          params
        );
      } catch (error) {
        response = error?.response || {};
        response.hasError = true;
      }
      return response;
    },
  },
};
